import * as React from 'react'
import styled from 'styled-components'

import * as glyphs from '../icons/glyphs'
import * as notification from '../styles/notification'

export type FlashProps = {
  className?: string
  /** @default true */
  closable?: boolean
  children: React.ReactNode
  type: notification.FlashNotificationType
  onClose?: () => void
}

function FlashComponent({
  className,
  closable = true,
  children,
  type,
  onClose,
}: FlashProps): React.ReactElement<FlashProps> {
  const isMultipleContent = Array.isArray(children)
  return (
    <notification.FlashNotification type={type} className={className}>
      <notification.FlashNotificationWrapper>
        <notification.FlashNotificationChildren isMultipleContent={isMultipleContent}>
          {children}
        </notification.FlashNotificationChildren>
        {closable && <glyphs.stroke.Close size={10} onClick={onClose} />}
      </notification.FlashNotificationWrapper>
    </notification.FlashNotification>
  )
}

export const Flash = styled(FlashComponent)<FlashProps>``
Flash.displayName = 'Flash'
