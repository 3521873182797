import { setLocale } from 'yup'

import { t } from '@owl-nest/localize'

let wasSet = false

if (!wasSet) {
  wasSet = true
  setLocale({
    mixed: {
      default: () => t('This field is invalid'),
      required: () => t('This field is required'),
    },
    number: {
      integer: () => t('This field must an integer'),
      max: ({ max }) => t('This field must be lower than or equal to %(max)d').replace('%(max)d', max),
      min: ({ min }) => t('This field must be greater than or equal to %(min)d').replace('%(min)d', min),
      positive: () => t('This field must be superior to 0'),
    },
    string: {
      email: () => t('Please enter a valid e-mail address'),
      max: ({ max }) => t('This field must contain a maximum of %(max)d characters').replace('%(max)d', max),
      min: ({ min }) => t('This field must contain at least %(min)d characters').replace('%(min)d', min),
      url: () => t('Please enter a valid URL address'),
    },
  })
}

function humanFileSize(size: number) {
  if (size < 1000) return size + ' B'
  const i = Math.floor(Math.log(size) / Math.log(1000))
  const num = size / Math.pow(1000, i)
  const round = Math.round(num)
  const formattedNum = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round
  return `${formattedNum} ${'KMGTPEZY'[i - 1]}B`
}

export const locales = {
  file: {
    max: (params: { max: number }) =>
      t('The file is too big. We allow files that are under %(maxSize)s', {
        maxSize: humanFileSize(params.max),
      }),
    formats: (params: { formats: string[] }) =>
      t('This type of file is not supported. We only support: %(supportedFiles)s.', {
        supportedFiles: params.formats.join(', '),
      }),
  },
  phone: {
    format: () => t('Phone number is invalid'),
    max: (params: { max: number }) =>
      t('Your phone number cannot be more than %(length)d digits', {
        length: params.max,
      }),
  },
}

export const strings = {
  slug: {
    formats: (params: { formats: string[] }) => t('Please enter a valid link. For example: my-project-link'),
  },
}
