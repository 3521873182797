import * as React from 'react'
import { ApiResponse, RequestFailure } from '@owl-nest/api-client'

export enum QueryStatus {
  PRISTINE,
  PENDING,
  SUCCESS,
  FAILURE,
}

export type Response<TYPE> =
  | { status: QueryStatus.PRISTINE; data?: undefined }
  | { status: QueryStatus.FAILURE; failure: RequestFailure; data?: TYPE }
  | { status: QueryStatus.PENDING; data?: TYPE }
  | { data: TYPE; status: QueryStatus.SUCCESS }

export function data<TYPE>(response?: Response<TYPE>): TYPE | undefined {
  if (
    response?.status === QueryStatus.SUCCESS ||
    response?.status === QueryStatus.PENDING ||
    response?.status === QueryStatus.FAILURE
  ) {
    return response.data
  }
}

export function assertData<TYPE>(response: Response<TYPE>): TYPE {
  const value = data(response)

  if (value === undefined) throw new Error('Failed data load')

  return value
}

export type QueryHelper<TYPE, PARAMS extends any[]> = [
  (...params: PARAMS) => void,
  Response<TYPE>,
  (data: TYPE) => void,
]

export function useQuery<TYPE, PARAMS extends any[] = []>(
  _query: (...params: PARAMS) => Promise<ApiResponse<TYPE>>,
): QueryHelper<TYPE, PARAMS> {
  const [response, setResponse] = React.useState<Response<TYPE>>({
    status: QueryStatus.PRISTINE,
  })

  async function query(...params: PARAMS): Promise<void> {
    setResponse((response) => {
      if (response.status === QueryStatus.SUCCESS) {
        return { data: response.data, status: QueryStatus.PENDING }
      }
      return { status: QueryStatus.PENDING }
    })

    const response = await _query(...params)

    response.caseOf({
      left: (failure) => setResponse({ failure, status: QueryStatus.FAILURE }),
      right: (success) => setResponse({ data: success, status: QueryStatus.SUCCESS }),
    })
  }

  function mutate(data: TYPE): void {
    if (response.status === QueryStatus.SUCCESS) {
      setResponse({ data, status: QueryStatus.SUCCESS })
    }
  }

  return [query, response, mutate]
}
