import * as React from 'react'
import styled from 'styled-components'

import * as S from '../../../styles'

type FieldStatusProps = {
  className?: string
  error?: React.ReactNode
  success?: React.ReactNode
  children?: React.ReactNode
}

function FieldStatusComponent({
  className,
  error,
  success,
  children,
}: FieldStatusProps): React.ReactElement<FieldStatusProps> {
  return (
    <S.field.Status className={className}>
      {error && <S.field.Message type="error">{error}</S.field.Message>}
      {success && <S.field.Message type="success">{success}</S.field.Message>}
      {children}
    </S.field.Status>
  )
}

export const FieldStatus = styled(FieldStatusComponent)``
