import * as React from 'react'
import styled from 'styled-components'

import * as select from '../../../behaviours/select'
import * as S from '../../../styles'
import { scrollContext, clientSideContext, useClientSideContext } from './context'
import { useLoseFocus } from '../../../hooks/useLoseFocus'
import { SearchInput } from '../SearchInput'
import * as hooks from '../../../hooks'
import * as COLORS from '../../../constants/colors'
import * as BREAKPOINTS from '../../../constants/breakpoints'
import { isMobile } from '../../../utils/matchMediaOnBrowser'
import { Option } from './Option'

export type SelectProps = Omit<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  'value' | 'onChange' | 'placeholder' | 'label' | 'title'
> & {
  /** set if needed for a `<label>` */
  id?: string
  className?: string
  disableInitFocus?: boolean
  /** currently selected values. Makes the component controlled. Incompatible with `initialSelected`. */
  /**
   * @default false */
  selected?: any[]
  /** initialy selected values. Makes the component uncontrolled. Incompatible with `selected` */
  initialSelected?: any[]
  /** usualy a list of `<Option>` */
  children?: React.ReactNode
  /** whether multiselect is allowed. In multiselect, `selected` is an array of any length. In singleselect, `selected` is an array of length 0 or 1.
   * @default false */
  multiple?: boolean
  native?: boolean
  /** handler called when selection changes with currently selected `<Option>`
   * @default () => {} */
  onChange?: (selected: any[]) => void
  /** handler called when dropdown closes with currently selected `<Option>`
   * @default () => {} */
  onClose?: (selected: any[]) => void
  /** handler called when dropdown open with currently selected `<Option>`
   * @default () => {} */
  onOpen?: () => void
  /** placeholder for the `<Select>` element */
  placeholder?: React.ReactNode
  /** label for the `<SelectButton>` element */
  label?: (selectedOptions: { label: string }[], placeholder: React.ReactNode) => React.ReactNode
  /** title for the `<SelectButton>` element */
  title?: (selectedOptions: { label: string }[]) => string
  /** placeholder for the filter inside the dropdown (if `filterable` is `true`). */
  filterPlaceholder?: string
  /** maximum height of the dropdown (scroll will be activated beyond) */
  height?: number
  status?: 'default' | 'focused' | 'error'
  /** whether the `<Option>` list is filterable.
   * @default false */
  filterable?: boolean
  /** whether the `<SelectButton>` is hidden in multiple mode.
   * @default false */
  hideSelectButton?: boolean
  /** Translation object */
  translations?: {
    validate: string
  }
}

export function SelectComponent(props: SelectProps): React.ReactElement<SelectProps> {
  const [isClientSide, setIsClientSide] = React.useState(false)
  React.useEffect(() => {
    setIsClientSide(true)
  }, [])

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    React.useEffect(() => {
      if (props.selected !== undefined && props.initialSelected !== undefined) {
        console.error(new Error('<Select> is both controlled and uncontrolled, use either selected or initialSelected'))
      }
    }, [])
  }

  if (process.env.NODE_ENV !== 'production') {
    React.useEffect(() => {
      if (props.native && props.multiple) {
        console.warn("<Select> with `native` mode can't have multiple choices")
      }
    }, [])
  }

  if (process.env.NODE_ENV !== 'production') {
    React.useEffect(() => {
      if (props.multiple && props.translations?.validate === undefined) {
        console.warn('<Select> with `multiple` mode needs a `translations.validate` prop')
      }
    }, [])
  }

  return (
    <clientSideContext.Provider value={isClientSide}>
      {props.native && isMobile() && isClientSide ? (
        <>
          {props.selected !== undefined ? (
            <ControlledNativeSelect {...props} />
          ) : (
            <UncontrolledNativeSelect {...props} />
          )}
        </>
      ) : (
        <>{props.selected !== undefined ? <ControlledSelect {...props} /> : <UncontrolledSelect {...props} />}</>
      )}
    </clientSideContext.Provider>
  )
}

function UncontrolledNativeSelect({
  children,
  initialSelected = [],
  onChange,
  ...props
}: SelectProps): React.ReactElement<SelectProps> {
  const initialSelectedOptions = initialSelected[0] ? [initialSelected[0]] : []
  const [selected, setSelected] = React.useState(initialSelectedOptions)

  const handleChange = (selected: any[]) => {
    onChange && onChange(selected)
    setSelected(selected)
  }

  return (
    <ControlledNativeSelect onChange={handleChange} selected={selected} {...props}>
      {children}
    </ControlledNativeSelect>
  )
}

function ControlledNativeSelect({
  children,
  className,
  onChange,
  placeholder,
  selected,
  label = defaultLabel,
  title = defaultTitle,
  ...props
}: SelectProps): React.ReactElement<SelectProps> {
  const isClientSide = useClientSideContext()
  const optionFormatted: { value: string; label: string }[] = []
  React.Children.forEach(children, (child) => {
    if (typeof child === 'object' && child !== null && 'props' in child) {
      const props = child.props
      if ('value' in props && typeof props.value === 'string' && 'label' in props && typeof props.label === 'string') {
        optionFormatted.push(child.props)
      }
    }
  })
  const selectedFormatted = isClientSide ? optionFormatted?.filter((option) => selected?.includes(option.value)) : []

  return (
    <S.select.Wrapper className={className} native={true}>
      <SelectButton
        disabled={props.disabled}
        id={props.id}
        status={props.status}
        onClick={() => {}}
        title={title?.(selectedFormatted?.map((selected) => ({ label: selected.label })) ?? [])}
      >
        {label(selectedFormatted?.map((selected) => ({ label: selected.label })) ?? [], placeholder)}
      </SelectButton>
      <select
        value={selectedFormatted?.[0]?.value}
        onChange={(event) => {
          let label = undefined
          for (const option of event.target) {
            if (option.value === event.target.value) {
              label = option.label ?? option.textContent
            }
          }
          onChange && onChange([{ value: event.target.value, label }])
        }}
      >
        {children}
      </select>
    </S.select.Wrapper>
  )
}

function UncontrolledSelect({
  children,
  className,
  disabled,
  disableInitFocus,
  filterable,
  filterPlaceholder,
  height,
  id,
  initialSelected = [],
  multiple,
  onChange,
  onClose,
  onOpen,
  placeholder,
  status,
  label,
  title,
}: SelectProps): React.ReactElement<SelectProps> {
  let initialSelectedOptions

  if (multiple) {
    initialSelectedOptions = initialSelected
  } else {
    initialSelectedOptions = initialSelected[0] ? [initialSelected[0]] : []
  }

  const [selected, setSelected] = React.useState(initialSelectedOptions)

  function handleChange(selected: select.OptionData[]): void {
    onChange && onChange(selected)
    setSelected(selected.map((option) => option.value))
  }

  return (
    <ControlledSelect
      className={className}
      disabled={disabled}
      disableInitFocus={disableInitFocus}
      filterable={filterable}
      filterPlaceholder={filterPlaceholder}
      height={height}
      id={id}
      multiple={multiple}
      onChange={handleChange}
      onClose={onClose}
      onOpen={onOpen}
      placeholder={placeholder}
      label={label}
      selected={selected}
      status={status}
      title={title}
    >
      {children}
    </ControlledSelect>
  )
}

function ControlledSelect({
  children,
  className,
  disabled,
  disableInitFocus = false,
  filterable = false,
  filterPlaceholder,
  height,
  id,
  multiple = false,
  onChange = () => {},
  onClose = () => {},
  onOpen = () => {},
  placeholder,
  selected = [],
  status,
  label = defaultLabel,
  title = defaultTitle,
  hideSelectButton,
  translations,
}: SelectProps): React.ReactElement<SelectProps> {
  const [{ isOpen, selectedOptions, open, close, register }, selectBoxRef, scrollRef, onFilter, filter] = useSelect(
    selected,
    onClose,
    onChange,
    multiple,
    disableInitFocus,
    filterable,
  )

  function openHandler(): void {
    onOpen()
    open()
  }

  hooks.useScrollLock(matchMedia(`screen and not ${BREAKPOINTS.TABLET}`).matches ? isOpen : 'preserve')

  const selectLabel = label(selectedOptions, placeholder || '')

  return (
    <S.select.Wrapper ref={selectBoxRef} className={className} native={false}>
      <SelectButton
        disabled={disabled}
        id={id}
        onClick={isOpen ? close : openHandler}
        status={status}
        title={title(selectedOptions)}
      >
        {selectLabel}
      </SelectButton>
      <SelectDrawer
        close={close}
        label={selectLabel}
        isOpen={isOpen}
        height={height}
        scrollRef={scrollRef}
        register={register}
        multiple={multiple}
        hideSelectButton={hideSelectButton}
        filterable={filterable}
        filter={filter}
        onFilter={onFilter}
        translations={translations}
        filterPlaceholder={filterPlaceholder}
      >
        {filter.length === 0
          ? children
          : React.Children.toArray(children).filter((child) => {
              return !React.isValidElement(child) || child.type === Option
            })}
      </SelectDrawer>
    </S.select.Wrapper>
  )
}

export function useSelect(
  selected: any[],
  onClose: (selected: select.OptionData[]) => void,
  onChange: (selected: select.OptionData[]) => void,
  multiple: boolean,
  disableInitFocus: boolean,
  filterable: boolean,
): [
  select.SelectApi,
  React.RefObject<HTMLDivElement>,
  React.RefObject<HTMLUListElement>,
  (filter: string) => void,
  string,
] {
  const selectBoxref = React.useRef<HTMLDivElement>(null)
  const listBoxRef = React.useRef<HTMLUListElement>(null)
  const [filter, setFilter] = React.useState('')
  const [debouncedFilter, setDebouncedFilter] = React.useState('')
  const debouncedUpdateFilter = hooks.useDebounce(setDebouncedFilter, false)
  const selectApi = select.useSelect(
    selected,
    selectBoxref,
    (selected) => {
      onClose(selected)
      setDebouncedFilter('')
      setFilter('')
    },
    onChange,
    multiple,
    disableInitFocus,
    filterable ? debouncedFilter : undefined,
  )

  useLoseFocus(
    selectBoxref,
    () => {
      selectApi.isOpen && selectApi.close()
    },
    [selectApi.isOpen],
  )

  const updateFilter = (filter: string): void => {
    setFilter(filter)
    debouncedUpdateFilter(filter)
  }

  return [selectApi, selectBoxref, listBoxRef, updateFilter, filter]
}

type SelectButtonProps = {
  className?: string
  disabled?: boolean
  id?: string
  onClick: () => void
  status?: 'default' | 'focused' | 'error'
  children?: React.ReactNode
  title?: HTMLButtonElement['title']
}

function SelectButtonComponent({
  className,
  disabled,
  id,
  onClick,
  status,
  children,
  title,
}: SelectButtonProps): React.ReactElement<SelectButtonProps> {
  return (
    <S.select.Button
      className={className}
      type="button"
      disabled={disabled}
      onClick={onClick}
      status={status}
      tabIndex={0}
      id={id}
      title={title}
    >
      <S.select.Label>{children}</S.select.Label>
      <S.select.CaretDown />
    </S.select.Button>
  )
}

export const SelectButton = styled(SelectButtonComponent)``

type SelectDrawerProps = {
  isOpen: boolean
  label?: React.ReactNode
  height?: number
  scrollRef: React.RefObject<HTMLUListElement>
  register: select.SelectApi['register']
  children?: React.ReactNode
  multiple?: boolean
  filterable?: boolean
  filter: string
  onFilter: (filter: string) => void
  filterPlaceholder?: string
  close: () => void
  hideSelectButton?: boolean
  translations?: {
    validate: string
  }
}

// FIXME: Placeholder should be plume.COLORS.SAND_700
export function SelectDrawer({
  isOpen,
  height,
  scrollRef,
  register,
  children,
  multiple,
  close,
  filterable,
  filter,
  onFilter,
  filterPlaceholder,
  hideSelectButton,
  translations,
}: SelectDrawerProps): React.ReactElement<SelectDrawerProps> {
  return (
    <S.select.Drawer isOpen={isOpen}>
      <S.dropdown.Dropdown>
        {filterable && (
          <S.select.SearchBox>
            <SearchInput
              placeholder={filterPlaceholder}
              value={filter}
              onChange={(evt) => onFilter(evt.target.value)}
            />
          </S.select.SearchBox>
        )}
        <S.select.ScrollList height={height} ref={scrollRef} tabIndex={-1}>
          <scrollContext.Provider value={scrollRef}>
            <select.SelectContextProvider value={register}>{children}</select.SelectContextProvider>
          </scrollContext.Provider>
        </S.select.ScrollList>
        {multiple && !hideSelectButton && translations?.validate && (
          <S.select.MultiSelectButton type="button" onClick={close} tabIndex={0}>
            {translations.validate}
          </S.select.MultiSelectButton>
        )}
      </S.dropdown.Dropdown>
    </S.select.Drawer>
  )
}

export function defaultLabel(selectedOptions: { label: string }[], placeholder: React.ReactNode): React.ReactNode {
  return selectedOptions.length === 0 ? placeholder : selectedOptions.map((option) => option.label).join(', ')
}
export function defaultTitle(selectedOptions: { label: string }[]): string {
  return selectedOptions.length === 0 ? '' : selectedOptions.map((option) => option.label).join(', ')
}

/** Replicate the native `<select>` behaviour (search by prefix, focus on hover, keyboard navigable). Usually used with `<Option>`.*/
export const Select = styled(SelectComponent)<SelectProps>`
  &:hover ${S.select.Button} {
    border: 1px solid ${COLORS.PRIMARY_BLUE};
    color: ${COLORS.PRIMARY_BLUE};

    &:disabled {
      border: 1px solid ${COLORS.GREY_SHADE_5};
    }
  }
`
Select.displayName = 'Select'
