export enum BRAND_ACTION {
  IMPRESSION = 'Brand Impression',
  IMPRESSION_CLICKABLE = 'Brand Impression Clickable',
  CLICK = 'Brand click',
  ON_PAGE = 'Brands on page',
}

export enum CATEGORY {
  BOUTIQUE = 'Boutique',
  CHECKOUT = 'Checkout',
  DISCOVER_ULULE = 'Discover Ulule',
  HOMEPAGE = 'Homepage',
  MEMBERSHIP_NEWS = 'Membership post',
  MEMBERSHIP_PAGE = 'Membership page',
  PARTNERS_OPERATIONS = 'Partners operations',
  PROJECT_FORM = 'project_form',
  PROJECT_PAGE = 'project_page',
  PROJECT_PROPOSAL_TRACKING = 'Project proposal',
  REGISTRATION = 'User registration',
  SOCIAL = 'social on Ulule',
  ULULE = 'Ulule',
  WIDGET = 'External widget',
}

export enum EVENT_TYPE {
  BRAND = 'BRANDevent',
  GENERIC = 'GAevent',
  PROJECT_FORM_ERROR = 'projectFormError',
  SOCIAL = 'socialInt',
  TRANSACTION = 'transaction',
}

export enum SITE {
  CHECKOUT = 'Checkout',
  ULULE = 'Ulule',
  WIDGET = 'External widget',
  CONNECT = 'Connect',
}

export enum SOCIAL_NETWORK {
  FACEBOOK = 'Facebook',
  HYPERLINK = 'Hyperlink',
  LINKEDIN = 'Linkedin',
  MESSENGER = 'Messenger',
  TWITTER = 'Twitter',
  ULULE = 'Ulule',
  WHATSAPP = 'WhatsApp',
  WIDGET = 'Widget',
}

export const apiTagTypeToGTM = {
  facebook: 'poFBpixelID',
  google: 'poGApropertyID',
  tiktok: 'poTTpixelID',
  twitter: 'poTWpixelID',
} as const

type AnalyticsTagType = (typeof apiTagTypeToGTM)[keyof typeof apiTagTypeToGTM]

export type AnalyticsTags = {
  [key in AnalyticsTagType]?: string
}

export type BaseTracking<EVENT extends Partial<TrackingEvent> = Partial<TrackingEvent>> = {
  track: (event: EVENT) => void
}

export type BrandEvent = {
  event: EVENT_TYPE.BRAND
  eventAction: BRAND_ACTION
  eventCategory: 'Brand visibility'
  eventLabel: string
  eventValue?: string
  logoId?: string
  nonInteractionEvent?: boolean
  linkUrl?: string
  eventTimeout?: number
  eventCallback?: () => void
}

export type GenericEvent = {
  event: EVENT_TYPE.GENERIC
  eventCategory: CATEGORY
  pageCategory: string
  siteCategory: SITE
  eventAction: string
  eventValue?: string
  eventLabel?: string
  eventTimeout?: number
  eventCallback?: () => void
}

export type SocialEvent = {
  event: EVENT_TYPE.SOCIAL
  eventCallback?: () => void
  eventCategory?: CATEGORY
  eventTimeout?: number
  socialAction: string
  socialNetwork: SOCIAL_NETWORK
  socialTarget: string
  ululeProjectId?: number
  eventAction?: string
  eventLabel?: string
  eventValue?: string
}

export type TransactionEvent = {
  event: EVENT_TYPE.TRANSACTION
  eventCallback?: () => void
  transactionId: number
  transactionAffiliation: 'Ulule'
  transactionTotal: number
  transactionCurrencyCode: string
  transactionProducts?: TransactionProductEvent[]
  ululeProjectId: number
}

export type TransactionProductEvent = {
  sku: number
  price: number
  quantity: number
}

export type TrackingEvent = GenericEvent | SocialEvent | TransactionEvent | BrandEvent
