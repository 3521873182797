import * as React from 'react'
import styled, { css } from 'styled-components'

import * as api from '@owl-nest/api-client/latest'
import * as date from '@owl-nest/date'
import * as metasettings from '@owl-nest/metasettings'
import * as plume from '@ulule/owl-kit-components/next'
import * as hooks from '@owl-nest/hooks'

import { useUserDrawerContext } from '../../context/userDrawer/userDrawerContext'
import * as S from '../style'

import { ContributionCard } from '../cards/ContributionCard'

import { Contribution, Project } from '../../types'
import * as UFE from '../../utils/UFE'
import { t } from '@owl-nest/localize'

type ProfileDrawerProps = {
  contributions: Contribution[]
  loading: boolean
  onClose: () => void
  open: boolean
  projects?: Project[]
  trigger: React.RefObject<HTMLElement>
  user: {
    createdProjectsCount: number
    unreadMessagesCount?: number
    urls: {
      contributionsUrl?: string
      profileUrl: string
      projectsUrl?: string
      settingsUrl?: string
    }
  }
}

const MAX_PROJECTS_TO_DISPLAY = 2
// This is shared between contributions and projects
const MAX_ITEMS_TO_DISPLAY = 5

export function ProfileDrawer({
  contributions = [],
  loading = true,
  onClose = () => {
    // noop
  },
  open = false,
  projects = [],
  trigger,
  user,
}: ProfileDrawerProps): React.ReactElement<ProfileDrawerProps> | null {
  const $drawerRef = React.useRef<HTMLDivElement>(null)
  const height100vh = hooks.use100vh()
  const { onTransitionEnd, step, unmounted } = plume.behaviours.useTransition(open)
  const { sessionData, translations, urls } = useUserDrawerContext()
  const [offset, setOffset] = React.useState(0)

  const languages = UFE.ALLOWED_LANGUAGES
  const currencies = UFE.ALLOWED_CURRENCIES
  const settings = hooks.useUserConfig(UFE.ULULE_ORIGINS[UFE.USER_LOCALE ?? UFE.DEFAULT_LOCALE])

  plume.hooks.useOnClickOutside([$drawerRef, trigger], () => onClose())

  React.useEffect(() => {
    if ($drawerRef.current) {
      setOffset($drawerRef.current.getBoundingClientRect().top)
    }
  }, [unmounted])

  if (unmounted) {
    return null
  }

  if (settings.data === undefined) {
    return <></>
  }

  const currentLanguage = settings.data.lang
  const currentCurrency = settings.data.currency

  return (
    <Drawer onTransitionEnd={onTransitionEnd} open={open} ref={$drawerRef} step={step}>
      <Content height100vh={height100vh} offset={offset ?? 0}>
        <ProfileLinksSection>
          <SectionHeading>{translations.myProfile}</SectionHeading>
          <ProfileLinks>
            <li>
              <S.Link href={user.urls.profileUrl}>{translations.myProfile}</S.Link>
            </li>
            {contributions.length > 0 && (
              <li>
                <S.Link href={user.urls.contributionsUrl}>{translations.myContributions}</S.Link>
              </li>
            )}
            <li>
              {user.unreadMessagesCount ? (
                <plume.Jewel number={user.unreadMessagesCount}>
                  <S.Link href={urls.inbox}>{translations.myMessages}</S.Link>
                </plume.Jewel>
              ) : (
                <S.Link href={urls.inbox}>{translations.myMessages}</S.Link>
              )}
            </li>
            <li>
              <S.Link href={user.urls.settingsUrl}>{translations.settings}</S.Link>
            </li>
            <li>
              <S.Link href={urls.signOut}>{translations.signOut}</S.Link>
            </li>
          </ProfileLinks>
        </ProfileLinksSection>
        <>
          {loading ? (
            <plume.Spinner />
          ) : (
            <>
              {projects.length > 0 && (
                <UserProjects>
                  <SectionHeading>{translations.projectsBoxTitle(projects.length)}</SectionHeading>
                  <UserContentList>
                    {projects.slice(0, MAX_PROJECTS_TO_DISPLAY).map((project) => (
                      <li key={project.absoluteUrl}>
                        <plume.HorizontalProjectCard
                          project={{
                            ...project,
                            name: project.name ?? translations.noProjectName,
                            subtitle: undefined,
                          }}
                          rates={sessionData.rates}
                          targetCurrency={sessionData.currentCurrency}
                          translation={{
                            comingSoon:
                              project.status === api.ProjectStatus.VALIDATED
                                ? translations.projectComingSoon.validatedProject
                                : translations.projectComingSoon.notValidatedProject,
                          }}
                        />
                      </li>
                    ))}
                  </UserContentList>
                  {user.createdProjectsCount > projects.length && user?.urls?.projectsUrl && (
                    <plume.styles.copy.S>
                      <plume.Link href={user.urls.projectsUrl} kind="secondary">
                        {translations.seeMore}
                      </plume.Link>
                    </plume.styles.copy.S>
                  )}
                </UserProjects>
              )}
              {contributions.length > 0 && (
                <UserContributions>
                  <SectionHeading>{translations.contributionsBoxTitle(contributions.length)}</SectionHeading>
                  <UserContentList>
                    {contributions
                      .slice(
                        0,
                        projects.length > 0 ? MAX_ITEMS_TO_DISPLAY - MAX_PROJECTS_TO_DISPLAY : MAX_ITEMS_TO_DISPLAY,
                      )
                      .map((contribution) => (
                        <li key={contribution.date}>
                          <ContributionCard
                            contribution={{
                              absoluteUrl: contribution.project.absoluteUrl,
                              amount: contribution.amount,
                              label: contribution.is_free ? t('Free membership') : undefined,
                              date: date.format(date.parseISO(contribution.date), 'P'), // TODO: Upper? ; Detail on hover?
                            }}
                            currentLocale={sessionData.currentLocale}
                            project={contribution.project}
                          />
                        </li>
                      ))}
                  </UserContentList>
                </UserContributions>
              )}
            </>
          )}
        </>
      </Content>
      <Footer>
        <FooterText>{translations.i18nSettingsLabel}</FooterText>
        <metasettings.MetaSettingDropdown
          currencies={currencies}
          currentCurrency={currentCurrency}
          currentLanguage={currentLanguage}
          hasOffset={true}
          languages={languages}
        />
      </Footer>
    </Drawer>
  )
}

const Section = styled.section``

const Content = styled.div<{ height100vh: string | null; offset: number }>`
  max-height: calc(${({ height100vh }) => height100vh} - 100px - ${({ offset }) => offset}px);
  overflow-y: auto;
  overflow-x: hidden;

  @supports (-webkit-touch-callout: none) {
    padding-bottom: 118px;

    @media screen and ${plume.BREAKPOINTS.MOBILE_M} {
      padding-bottom: 0;
    }
  }
`

const Drawer = styled.nav<{ open: boolean; step: plume.behaviours.UseTransition['step'] }>`
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // min-height prevents currencies pop-up hiding when not enough height is available
  min-height: 440px;
  padding: 10px;
  position: fixed;
  right: 0;
  top: ${plume.SIZES.HEADER_HEIGHT};
  transition-duration: 300ms;
  transition-property: transform, visibility;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  width: 315px;
  z-index: 2;

  @media screen and ${plume.BREAKPOINTS.MOBILE_M} {
    padding: 20px;
  }

  ${Section} {
    :not(:last-child) {
      margin-bottom: 40px;
    }
  }

  ${({ step }) => {
    if (step === 'before') {
      return css`
        transform: translate3d(100%, 0, 0);
      `
    }
    if (step === 'during') {
      return css`
        transform: translate3d(0, 0, 0);
      `
    }
    if (step === 'after') {
      return css`
        transform: translate3d(100%, 0, 0);
      `
    }
  }}
`

const Footer = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;

  ${plume.styles.i18nSettingsBox.LanguagesBox} {
    transform: translate3d(-76%, -100%, 0);
  }

  ${plume.styles.i18nSettingsBox.CurrenciesBox} {
    transform: translate3d(-74%, -100%, 0);
  }

  @media screen and ${plume.BREAKPOINTS.MOBILE_M} {
    ${plume.styles.i18nSettingsBox.CurrenciesBox} {
      transform: translate3d(-80%, -100%, 0);
    }
  }
`

const FooterText = styled(plume.styles.heading.XXXXXS)`
  margin-bottom: 9px;
`

const ProfileLinks = styled.ul`
  list-style: none;
  padding: 0;

  > * {
    margin-bottom: 15px;
  }
`

const ProfileLinksSection = styled(Section)``

const UserContentList = styled.ul`
  list-style: none;
  padding: 0;

  > li {
    padding-bottom: 15px;

    &:last-child {
      padding-bottom: 10px;
    }
  }
`

const SectionHeading = styled(plume.styles.heading.XXXXXS).attrs({ as: 'header' })`
  margin-bottom: 18px;
`

const UserContributions = styled(Section)``

const UserProjects = styled(Section)``
