import * as React from 'react'
import { BrandTracking } from './brand/types'
import { ProjectTracking } from './project/types'
import { SocialTracking } from './social/types'
import { TransactionTracking } from './transaction/types'
import { tracking } from './base/tracking'
import { tracking as brandTracking } from './brand/tracking'
import { tracking as socialTracking } from './social/tracking'
import { tracking as projectTracking } from './project/tracking'
import { tracking as transactionTracking } from './transaction/tracking'

import { Tracking } from './base/types'
import { safePusher } from './safePush'

export type TrackingContext = Tracking & {
  brand: BrandTracking
  project: ProjectTracking
  social: SocialTracking
  transaction: TransactionTracking
}

export const trackingContext = React.createContext<TrackingContext>({
  ...tracking(safePusher()),
  brand: brandTracking(safePusher()),
  social: socialTracking(safePusher()),
  project: projectTracking(safePusher()),
  transaction: transactionTracking(safePusher()),
})
