import { EVENT_TYPE, TrackingEvent } from './types'

export type SafePush = <EVENT extends TrackingEvent = TrackingEvent>(event: EVENT) => void

export function safePusher(gtmContainerId?: string) {
  return <EVENT extends TrackingEvent = TrackingEvent>(event: EVENT): void => {
    if (gtmContainerId === undefined) {
      console.warn('[shadow] tracking was not initialized (need gtmContainerId)')
    }

    if (window.dataLayer === undefined || window.google_tag_manager === undefined) {
      return simulatePush(event)
    }

    if (event.event === EVENT_TYPE.GENERIC && !(window.ga !== undefined && window.ga.loaded === true)) {
      simulatePush(event)
    } else {
      const callback = event.eventCallback
      const safeEvent: any = { ...event }
      if (callback) {
        // since the callback can be called by the `eventCallback` and
        // `setTimeout`, we want to make sure `callback` is only called once
        const onceCallback = once(callback)
        if (!safeEvent.eventTimeout) {
          safeEvent.eventTimeout = 1000
        }

        safeEvent.eventCallback = (containerId: string) => {
          /**
           * A bit of context: the native `eventCallback()` has an `id` argument
           * that represents the container within which it is executed.
           * Shimmed/stubbed versions do not have access to the ID, thus not passing
           * the argument. We test that through `!containerId` and manually execute
           * the callback in that case.
           */
          if (containerId === gtmContainerId || !containerId) {
            onceCallback()
          }
        }

        /**
         * As a last resort, force manually invoking callback after 1s. This is for cases where:
         *  - GTM fails to finish sending the event or timing out
         *  - GTM is stubbed by the browser (hi there, strict mode Firefox) and the callback isn't naturally called
         */
        setTimeout(onceCallback, 1200)
      }
      window.dataLayer.push(safeEvent)
    }
  }
}

// return a function that will call `callback` only once
function once(callback: () => void): () => void {
  let called = false
  return () => {
    if (!called) {
      called = true
      callback()
    }
  }
}

function simulatePush(event: TrackingEvent): void {
  // try to push anyway, we never know, but without callback
  if (window.dataLayer !== undefined) {
    window.dataLayer.push({
      ...event,
      eventCallback: undefined,
    })
  }

  // run the callback ourselves
  const callback = event.eventCallback
  if (callback !== undefined) {
    setTimeout(callback, 0)
  }
}
