import styled, { css } from 'styled-components'

import * as colors from '../../constants/colors'
import * as fonts from '../../constants/fonts'

export const focusedBorder = css`
  border: ${({ theme }) => `1px solid ${theme?.colors?.primary ?? colors.PRIMARY_BLUE_500}`};
`

export const errorBorder = css`
  border: 1px dashed ${colors.BORDER_ERROR};
`

export const successBorder = css`
  border: 1px solid ${colors.SECONDARY_GREEN_500};
`

export const defaultBorder = css`
  border: 1px solid ${colors.PRIMARY_SAND_200};
`

export const inputStyles = css`
  /* Safari adds circles around numbers inside date inputs. To prevent that, we're forcing 'initial' on 'font-feature-settings' */
  font-feature-settings: initial;

  /* These rules are here to prevent Blink & WebKit from adding its own input icons */

  [type='date'],
  [type='time'] {
    -webkit-appearance: none;
  }

  ::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  height: 50px;
  border-radius: 2px;
  padding: 15px;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  font-weight: 400;
  outline-color: ${({ theme }) => theme?.colors?.primary ?? colors.PRIMARY_BLUE_500};
  background-color: ${colors.PRIMARY_GREY_000};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
`

export const disabledInputStyles = css`
  ${defaultBorder};
  background-color: ${colors.SECONDARY_GREY_400};
  color: ${colors.GREY_SHADE_3};
`

export type Status = 'error' | 'success' | 'default'

export const Input = styled.input<{ status?: Status }>`
  ${inputStyles};

  &:focus {
    ${focusedBorder};
    outline: none;
  }

  &::placeholder {
    font-family: ${fonts.PRIMARY_FONT};
    color: ${colors.GREY_SHADE_3};
    font-size: 15px;
    font-weight: 400;
  }

  ${({ status }) => {
    if (status === 'error') {
      return errorBorder
    }
    if (status === 'success') {
      return successBorder
    }
    return defaultBorder
  }}
  &:disabled {
    ${disabledInputStyles};
  }
`

export const HiddenFocusableInput = styled.input<{ disabled?: boolean }>`
  position: absolute;
  display: inline-block;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  border: 0;
`

export const Prefix = styled.div`
  align-items: center;
  background-color: ${colors.PRIMARY_GREY_000};
  border-bottom: 1px solid ${colors.PRIMARY_SAND_200};
  border-left: 1px solid ${colors.PRIMARY_SAND_200};
  border-top: 1px solid ${colors.PRIMARY_SAND_200};
  box-sizing: border-box;
  color: ${colors.GREY_SHADE_3};
  display: flex;
  flex: none;
  height: 50px;
  justify-content: center;
  padding: 0 14px;
  white-space: nowrap;
`

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ComposedWrapper = styled.div<{ disabled?: boolean; status?: Status }>`
  ${defaultBorder};
  background-color: ${colors.PRIMARY_GREY_000};
  display: flex;
  flex-direction: row;

  ${Input}, ${Input}:focus {
    background-color: transparent;
    border: none;
  }

  &:focus-within {
    ${focusedBorder}
  }

  ${({ status }) => {
    if (status === 'error') {
      return errorBorder
    }
    if (status === 'success') {
      return successBorder
    }
    return defaultBorder
  }}
  &[disabled] {
    ${defaultBorder};
    background-color: ${colors.SECONDARY_GREY_400};
  }
`

export const Count = styled.span<{ isError: boolean }>`
  font-size: 10px;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  ${({ isError }) => {
    if (isError) {
      return css`
        color: ${colors.BORDER_ERROR};
      `
    } else {
      return css`
        color: ${colors.TEXT_SECONDARY};
      `
    }
  }}
`
