import styled, { css } from 'styled-components'

import { UseTransition } from '../behaviours/transition'
import * as bp from '../constants/breakpoints'
import * as colors from '../constants/colors'
import * as fonts from '../constants/fonts'
import * as zindex from '../constants/zindex'
import * as glyphs from '../icons/glyphs'
import { hexToRgb } from '../utils/colors'

import { UnstyledButton } from './button'

export const BaseNotification = styled.div`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
`

export const FIXED_NOTIFICATION_COLOR = {
  alert: colors.SECONDARY_YELLOW,
  error: colors.PRIMARY_RED_500,
  info: colors.PRIMARY_BLUE_500,
  success: colors.SECONDARY_GREEN_500,
}

// CALLOUT
export type CalloutType = keyof typeof FIXED_NOTIFICATION_COLOR

export const Callout = styled(BaseNotification)<{ type: CalloutType; size: string }>`
  color: ${colors.PRIMARY_BLACK};
  ${({ type }) => {
    return css`
      background-color: ${hexToRgb(FIXED_NOTIFICATION_COLOR[type], type == 'info' ? 0.1 : 0.15)};

      ${glyphs.Icon} {
        color: ${FIXED_NOTIFICATION_COLOR[type]};
      }
    `
  }}

  ${(props) => {
    if (props.size === 'small') {
      return css`
        padding: 8px;
        font-size: 13px;
        line-height: 18px;
      `
    }
    if (props.size === 'medium') {
      return css`
        padding: 18px 15px;
      `
    }
  }}
`

export const CalloutBackground = styled.div`
  background-color: white;
`

export const CalloutSpan = styled.span``

export const CalloutWrapper = styled.div<{ size: string }>`
  align-items: flex-start;
  display: flex;
  flex-direction: row;

  ${glyphs.Icon} {
    flex: 0 0 18px;
    position: relative;

    ${(props) => {
      if (props.size === 'small') {
        return css`
          margin-right: 6px;
          top: 0;
        `
      } else if (props.size === 'medium') {
        return css`
          margin-right: 15px;
          top: 4px;
        `
      }
    }};
  }
`

// FLASH
const flashNotificationColor = {
  alert: colors.SECONDARY_RED,
  info: colors.PRIMARY_BLACK,
  notification: colors.PRIMARY_BLUE,
  highlight: colors.SECONDARY_STATE_YELLOW_200,
  success: colors.PRIMARY_GREEN,
}

export type FlashNotificationType = keyof typeof flashNotificationColor

export const FlashNotification = styled(BaseNotification)<{ type: FlashNotificationType }>`
  color: ${colors.PRIMARY_WHITE};
  left: 1px;
  padding: 18px 36px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${zindex.TOAST};

  ${({ type }) => {
    return css`
      background-color: ${flashNotificationColor[type]};
    `
  }}
`

export const FlashNotificationWrapper = styled.div`
  text-align: center;

  ${glyphs.stroke.Close} {
    color: ${colors.PRIMARY_WHITE};
    position: absolute;
    right: 18px;
    top: 8px;
  }

  @media screen and ${bp.LAPTOP} {
    ${glyphs.stroke.Close} {
      color: ${colors.PRIMARY_WHITE};
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

// TODO : Maybe we need to export this in other file
export const CenterSlot = styled.div``
export const LeftSlot = styled.div``
export const RightSlot = styled.div``

export const FlashNotificationChildren = styled.span<{ isMultipleContent: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: ${({ isMultipleContent }) => (isMultipleContent ? 'space-between' : 'center')};
  width: 100%;

  @media screen and ${bp.LAPTOP} {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  ${CenterSlot} {
    flex: 1;
    flex-direction: row;

    @media screen and ${bp.LAPTOP_L} {
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }
`

// TOAST
export const toastColor = {
  alert: {
    background: hexToRgb(colors.SECONDARY_YELLOW, 0.15),
    accent: colors.SECONDARY_YELLOW,
    text: colors.PRIMARY_BLACK,
  },
  error: {
    background: hexToRgb(colors.PRIMARY_RED, 0.15),
    accent: colors.PRIMARY_RED,
    text: colors.PRIMARY_BLACK,
  },
  info: {
    background: hexToRgb(colors.PRIMARY_BLUE, 0.1),
    accent: colors.PRIMARY_BLUE,
    text: colors.PRIMARY_BLACK,
  },
  success: {
    background: hexToRgb(colors.PRIMARY_GREEN, 0.15),
    accent: colors.PRIMARY_GREEN,
    text: colors.PRIMARY_BLACK,
  },
  neutral: {
    background: colors.PRIMARY_BLACK,
    accent: colors.GREY_SHADE_2,
    text: colors.PRIMARY_WHITE,
  },
}
export type ToastType = keyof typeof toastColor

export type ToastTypeWithoutNeutral = keyof typeof FIXED_NOTIFICATION_COLOR

export const ToastBackground = styled.div<{
  step: UseTransition['step']
  position: 'bottom' | 'top'
}>`
  background-color: ${colors.PRIMARY_WHITE};
  border-radius: 2px;
  bottom: 0;
  opacity: 1;
  position: relative;
  transition-property: bottom, opacity;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);

  ${({ step, position }) => {
    switch (step) {
      case 'before': {
        return css`
          bottom: ${position === 'bottom' ? '-10px' : '50px'};
          opacity: 0;
        `
      }
      case 'after': {
        return css`
          bottom: ${position === 'bottom' ? '-10px' : '50px'};
          opacity: 0;
        `
      }
    }
  }};
`

export const ToastContainer = styled(BaseNotification)<{
  type: ToastType
}>`
  align-items: flex-end;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  height: fit-content;
  justify-content: space-between;
  padding: 12px 15px;

  ${glyphs.Icon} {
    flex: 0 0 18px;
    margin-right: 15px;
    position: relative;
  }

  ${({ type }) => {
    return css`
      color: ${toastColor[type].text};
      background-color: ${toastColor[type].background};
      border-bottom: 3px solid ${toastColor[type].accent};

      ${glyphs.Icon} {
        color: ${toastColor[type].accent};
      }

      ${UnstyledButton} {
        color: ${toastColor[type].text};
      }
    `
  }}
`

export const ToastContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

export const ToastActions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

export const ToastAction = styled(UnstyledButton)`
  margin: 0 8px;
`

export const ToastPrimaryAction = styled(ToastAction)`
  font-weight: 700;
`
