import * as React from 'react'
import styled from 'styled-components'

import { t } from '@owl-nest/localize'
import * as plume from '@ulule/owl-kit-components/next'

type PreviewBarProps = {
  previousEditionPath: string
  onShare?: () => void
  translations?: {
    backToEdition: string
  }
}

export function PreviewBar({
  previousEditionPath,
  onShare,
  translations,
}: PreviewBarProps): React.ReactElement<unknown> {
  return (
    <StyledFlash type="notification" closable={false}>
      <plume.styles.notification.LeftSlot>
        <plume.styles.copy.S as={plume.Link} href={previousEditionPath}>
          <plume.glyphs.stroke.ArrowLeft size={15} />
          <HiddenOnMobile>{translations?.backToEdition ?? t('Back to project edition')}</HiddenOnMobile>
        </plume.styles.copy.S>
      </plume.styles.notification.LeftSlot>

      <plume.styles.notification.CenterSlot>
        <plume.styles.heading.XXXXS>
          <plume.glyphs.stroke.Read size={15} /> {t('Preview')}
        </plume.styles.heading.XXXXS>
      </plume.styles.notification.CenterSlot>

      {onShare && (
        <plume.styles.notification.RightSlot>
          <plume.LinkAsButton onClick={onShare}>
            <HiddenOnMobile>{t('Share your project')}</HiddenOnMobile>
            <plume.glyphs.stroke.Share size={15} />
          </plume.LinkAsButton>
        </plume.styles.notification.RightSlot>
      )}
    </StyledFlash>
  )
}

const HiddenOnMobile = styled.span`
  display: none;
  @media screen and ${plume.BREAKPOINTS.TABLET} {
    display: inline;
  }
`

const StyledFlash = styled(plume.Flash)`
  background-color: ${plume.COLORS.hexToRgb(plume.COLORS.BRAND_ACCENT_YELLOW, 0.5)};
  padding: 12px;
  position: relative;
  pointer-events: all;

  ${plume.styles.notification.FlashNotificationChildren} {
    flex-direction: row;
  }

  ${plume.styles.notification.CenterSlot} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
  }

  ${plume.glyphs.Icon} {
    margin: 0 8px;
  }

  ${plume.glyphs.stroke.Read} {
    position: relative;
    top: -1px;
  }

  ${plume.styles.heading.XXXXS} {
    color: ${plume.COLORS.PRIMARY_BLACK};
    margin-bottom: 5px;
    line-height: unset;

    @media screen and ${plume.BREAKPOINTS.TABLET} {
      margin: 0px;
    }
  }

  ${plume.Link}, ${plume.LinkAsButton} {
    text-decoration: none;
    font-weight: 500;
    padding: 0;

    &:hover {
      color: ${plume.COLORS.PRIMARY_BLUE_HOVER};
    }
  }
`
