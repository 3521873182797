import * as React from 'react'
import styled from 'styled-components'

import * as glyphs from '../../../icons/glyphs'
import * as S from '../../../styles'

export type FieldProps = React.HTMLAttributes<HTMLDivElement> & {
  context?: string
  description?: React.ReactNode
  disabled?: boolean
  help?: React.ReactNode
  htmlFor?: string
  format?: 'wide'
  label?: React.ReactNode
}

function FieldComponent({
  children,
  className,
  context,
  description,
  disabled,
  help,
  htmlFor,
  label,
  format,
  ...props
}: FieldProps): React.ReactElement<FieldProps> {
  return (
    <S.field.Label className={className} disabled={disabled} {...props}>
      {(label !== undefined || help) && (
        <S.field.FieldHead>
          {label !== undefined && (
            <S.field.ActualLabel htmlFor={htmlFor}>
              <S.field.TitleWrapper>
                <S.field.Title>{label}</S.field.Title>
                {context !== undefined && <S.field.Context>{context}</S.field.Context>}
              </S.field.TitleWrapper>
            </S.field.ActualLabel>
          )}
          {help && (
            <S.field.Help>
              <glyphs.stroke.Bulb size={15} />
              <S.link.Primary as="div" tinted href="none">
                {help}
              </S.link.Primary>
            </S.field.Help>
          )}
        </S.field.FieldHead>
      )}
      {description && <S.field.Description format={format}>{description}</S.field.Description>}
      {children}
    </S.field.Label>
  )
}

export const Field = styled(FieldComponent)``
