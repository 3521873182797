import styled, { css } from 'styled-components'

import * as colors from '../../constants/colors'
import * as effects from '../../constants/neue/effects'
import * as fonts from '../../constants/fonts'
import * as glyphs from '../../icons/glyphs'
import * as heading from '..//heading'
import * as copy from '../copy'
import * as link from '../link'

import { Tag, RoleTag } from '../tag'

type MembershipCardWrapperProps = {
  bordered?: boolean
  fullWidth?: boolean
}

export const MembershipCardWrapper = styled.div<MembershipCardWrapperProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 2px;
  background-color: ${colors.PRIMARY_GREY_000};
  box-shadow: ${effects.SHADOW_2};
  gap: 16px;
  border: ${({ bordered, theme }) => bordered && `2px solid ${theme?.colors?.primary ?? colors.PRIMARY_BLUE_500}`};
  transition: box-shadow 300ms ease-in-out;

  ${({ fullWidth }) => {
    if (!fullWidth) {
      return css`
        max-width: 288px;
      `
    }
  }}

  & > :last-child {
    margin-top: auto;
  }

  :hover {
    box-shadow: ${effects.SHADOW_5};
  }

  & > :last-child {
    margin-top: auto;
  }

  :hover {
    box-shadow: ${effects.SHADOW_5};
  }

  ${Tag} {
    font-family: ${fonts.PRIMARY_FONT};
    font-size: 14px; // HACK: Irregular font manipulation
    line-height: 22px; // HACK: Irregular font manipulation
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 2px 6px;

    ${glyphs.Icon} {
      top: 1px !important;
    }
  }
`

export const MemberTag = styled(Tag)`
  align-items: center;
  display: flex;
  gap: 8px;
  left: 50%;
  padding-inline: 7px;
  position: absolute;
  top: -15px;
  transform: translateX(-50%);

  ${glyphs.Icon} {
    margin-right: 0 !important;
  }
`

export const MembershipCardImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  height: 136px;
`

export const MembershipCardTitle = styled(heading.XS)`
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  position: relative;
`

export const MembershipStatus = styled.div`
  box-sizing: border-box;
  background-color: ${colors.PRIMARY_GREY_000};
  border-radius: 4px;
  box-shadow: ${effects.SHADOW_5};
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  span {
    align-self: flex-start;
    display: inline-flex;
    gap: 8px;

    ${glyphs.Icon} {
      color: ${({ theme }) => `${theme?.colors?.primary ?? colors.PRIMARY_BLUE_500}`};
    }
  }
`

export const MembershipStatusEditCTA = styled(copy.S)`
  ${link.Base} {
    display: flex;
    align-items: center;
    font-weight: 600; // HACK: Irregular font manipulation.
    gap: 8px;
  }
`

export const TaxDeduction = styled(copy.S)`
  color: ${colors.PRIMARY_SAND_700};
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: center;

  ${glyphs.Icon} {
    color: ${colors.PRIMARY_SAND_500};
  }
`

export const MembershipCardContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const MembershipCardSubTitle = styled(copy.S)`
  font-weight: 700; // HACK: Irregular font manipulation.
`

export const MembershipCardDescription = styled(copy.S)`
  p,
  ul,
  ol {
    margin: 0;
    white-space: pre-wrap;
  }
`

export const MembershipCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const MembershipCardSubsRemaining = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: auto;
  gap: 2px;

  & > :nth-child(2) {
    color: ${({ theme }) => `${theme?.colors?.primary ?? colors.PRIMARY_BLUE_500}`};
  }
`

export const MembershipCardRole = styled(copy.S)`
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 8px;

  i,
  em {
    font-weight: 600; // HACK: Irregular font manipulation. We'll be adding copy.S.semiBold, so this is just by anticipation.
  }

  p {
    margin: 0;
  }

  ${glyphs.Icon} {
    color: ${colors.PRIMARY_BLUE_500};
  }
`
