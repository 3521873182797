import styled, { css } from 'styled-components'

import * as breakpoints from '../../constants/breakpoints'
import * as colors from '../../constants/colors'
import * as copy from '../copy'
import * as fonts from '../../constants/fonts'
import * as glyphs from '../../icons/glyphs'
import * as heading from '../heading'

export const Description = styled(copy.S)<{ format?: 'wide' }>`
  ${({ format }) => {
    if (format === 'wide') {
      return css`
        @media screen and ${breakpoints.LAPTOP} {
          width: 85%;
        }
      `
    }
  }};
`

// TODO: rename application wide to Field or Wrapper
export const Label = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ disabled = false }) => {
    if (disabled) {
      return css`
        cursor: not-allowed;
      `
    }
  }}
`

// TODO: rename application wide to Label
export const ActualLabel = styled.label``

export const Status = styled.div`
  display: flex;
  flex-direction: row;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  @media screen and ${breakpoints.TABLET} {
    flex-direction: row;
  }
`

export const Title = styled.span`
  align-items: center;
  color: ${colors.PRIMARY_BLACK};
  display: flex;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 15px;
  margin-top: -2px; // hack for vertical font centering
`

export const Context = styled.span`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${colors.GREY_SHADE_3};
  text-transform: uppercase;
  white-space: pre;
  overflow: hidden;
`

export const Message = styled.div<{ type?: 'error' | 'success' }>`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  ${({ type }) => {
    if (type === 'error') {
      return css`
        color: ${colors.SECONDARY_RED};
      `
    }
    if (type === 'success') {
      return css`
        color: ${colors.PRIMARY_GREEN};
      `
    }
  }}
`

export const Help = styled(heading.Card1)`
  align-items: center;
  display: flex;
  margin: 9px 0 5px;
  position: relative;
  top: -3px;

  ${glyphs.stroke.Bulb} {
    color: ${colors.GREY_SHADE_7};
    margin-right: 4px;
  }

  @media screen and ${breakpoints.LAPTOP} {
    margin: 0;
  }
`

export const FieldHead = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;

  @media screen and ${breakpoints.LAPTOP} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`
