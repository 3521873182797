import * as _accordion from './accordion'
import * as _badge from './badge'
import * as _button from './button'
import * as _categoryCard from './card/category'
import * as _criterion from './card/criterion'
import * as _membershipCard from './card/membership'
import * as _miniChannelCard from './card/miniChannelCard' // TODO: Group w/ `channelCard`
import * as _newsCard from './card/news'
import * as _partner from './card/partner'
import * as _productCard from './card/product'
import * as _projectCard from './card/project'
import * as _carousel from './carousel'
import * as _cartItemBody from './cartItem/cartItemBody'
import * as _cartItemFooter from './cartItem/cartItemFooter'
import * as _cartItemHeader from './cartItem/cartItemHeader'
import * as _channelCard from './channelCard'
import * as _container from './container'
import * as _copy from './copy'
import * as _dropdown from './dropdown'
import * as S from './form/autocomplete'
import * as _avatar from './form/avatar'
import * as _checkbox from './form/checkbox'
import * as _colorPicker from './form/colorPicker'
import * as _controls from './form/controls'
import * as _datePicker from './form/datePicker'
import * as _field from './form/field'
import * as _input from './form/input'
import * as _option from './form/option'
import * as _password from './form/password'
import * as _phone from './form/phone'
import * as _radio from './form/radio'
import * as _range from './form/range'
import * as _select from './form/select'
import * as _switch from './form/switch'
import * as _textarea from './form/textarea'
import * as _gallery from './gallery'
import * as _glyphPastille from './glyphPastille'
import * as _i18nSettingsBox from './i18nSettingsBox'
import * as _image from './image'
import * as _imageUploader from './imageUploader'
import * as _jewel from './jewel'
import * as _link from './link'
import * as _menu from './menu'
import * as _modal from './modal'
import * as _notification from './notification'
import * as _pagination from './pagination'
import * as _picture from './picture'
import * as _placeholder from './placeholder'
import * as _progressBar from './progressBar'
import * as _quantityInput from './quantityInput'
import * as _reward from './reward'
import * as _shadowBox from './shadowBox'
import * as _skeleton from './skeleton'
import * as _socialShare from './socialShare'
import * as _spinner from './spinner'
import * as _squarePlatformIcon from './squarePlatformIcon'
import * as _tab from './tab'
import * as _tag from './tag'
import * as _toggle from './toggle'
import * as _tooltip from './tooltip'
import * as _userCard from './userCard'
import * as _userLabel from './userLabel'
import * as _video from './video'
import * as _wysiwyg from './wysiwyg'

export * as breadcrumbs from './breadcrumbs'
export * as heading from './heading'

export const autocomplete = S
export const accordion = _accordion
export const avatar = _avatar
export const button = _button
export const badge = _badge
export const channelCard = _channelCard
export const card = {
  category: _categoryCard,
  channel: _miniChannelCard,
  criterion: _criterion,
  membership: _membershipCard,
  news: _newsCard,
  partner: _partner,
  product: _productCard,
  project: _projectCard,
}
export const carousel = _carousel
export const cartItemBody = _cartItemBody
export const cartItemFooter = _cartItemFooter
export const cartItemHeader = _cartItemHeader
export const checkbox = _checkbox
export const colorPicker = _colorPicker
export const container = _container
export const controls = _controls
export const copy = _copy
export const datePicker = _datePicker
export const dropdown = _dropdown
export const field = _field
export const gallery = _gallery
export const glyphPastille = _glyphPastille
export const image = _image
export const imageUploader = _imageUploader
export const input = _input
export const i18nSettingsBox = _i18nSettingsBox
export const jewel = _jewel
export const link = _link
export const menu = _menu
export const modal = _modal
export const notification = _notification
export const option = _option
export const pagination = _pagination
export const password = _password
export const picture = _picture
export const placeholder = _placeholder
export const phone = _phone
export const progressBar = _progressBar
export const quantityInput = _quantityInput
export const radio = _radio
export const range = _range
export const reward = _reward
export const select = _select
export const shadowBox = _shadowBox
export const skeleton = _skeleton
export const spinner = _spinner
export const squarePlatformIcon = _squarePlatformIcon
export const socialShare = _socialShare
export const switchElement = _switch
export const tab = _tab
export const tag = _tag
export const textarea = _textarea
export const toggle = _toggle
export const tooltip = _tooltip
export const userCard = _userCard
export const userLabel = _userLabel
export const video = _video
export const wysiwyg = _wysiwyg
